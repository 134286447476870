import { Controller } from '@hotwired/stimulus'
import parsePhoneNumber from 'libphonenumber-js/max'

export default class extends Controller {
  static targets = [
    'postCodeInput',
    'postCodeError',
    'telInput',
    'telError',
    'submit',
    'disease',
    'diseaseList',
    'diseaseItem',
  ]

  validatePostCode () {
    const value = this.postCodeInputTarget.value
    if (value.match(/^\d{3}-*\d{4}$/)) {
      this.postCodeErrorTarget.style.display = 'none'
    } else {
      this.postCodeErrorTarget.style.display = 'flex'
    }
  }

  validateTel () {
    const value = this.telInputTarget.value
    if (value) {
      const phoneNumber = parsePhoneNumber(value, 'JP')
      if (phoneNumber?.isValid()) {
        this.submitTarget.disabled = false
        this.telErrorTarget.style.display = 'none'
      } else {
        this.telErrorTarget.style.display = 'flex'
      }
    }
  }

  changeDiseaseList () {
    if (Number(this.presenceDisease) === 0) {
      this.diseaseListTarget.style.display = 'none'
      this.diseaseItemTargets.forEach(element => (element.checked = false))
      this.diseaseItemTargets.forEach(element => (element.required = false))
    } else {
      this.diseaseListTarget.style.display = 'block'
      this.diseaseItemTargets.forEach(element => (element.required = true))
    }
  }

  get presenceDisease () {
    let presence = 0
    this.diseaseTargets.forEach(element => {
      if (element.checked) {
        presence = element.value
      }
    })
    return presence
  }

  changeDiseaseRequired () {
    if (this.diseaseItemHasChecked) {
      this.diseaseItemTargets.forEach(element => (element.required = false))
    } else {
      this.diseaseItemTargets.forEach(element => (element.required = true))
    }
  }

  get diseaseItemHasChecked () {
    return this.diseaseItemTargets.some(element => {
      if (element.checked === true) {
        return true
      }
    })
  }
}
